@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100&display=swap');

body { min-height: 100vh; margin: 0; background-color: #faf5f0}

.App {
  text-align: center;
  background-color: #faf5f0;
  color: #2e3440;
  min-height: 100vh;
  width: 100%;
  margin: auto;
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
}




